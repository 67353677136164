import React, { useState, useEffect, lazy, Suspense } from "react";
import "./userPage.css";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import SongsContext from "../../components/SongsContext";
import { useUser } from "../../components/UserContext";
import { useAddedSongs } from "../../components/AddedSongsContext";
import jwtDecode from 'jwt-decode';
import Popup from "../../components/Popup/Popup";
import logo from '../../assets/images/vcpmc.gif'

import { TextField, InputAdornment, IconButton, Tooltip, Stack, Typography, Modal, Box, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QueueMusicIcon from '@mui/icons-material/QueueMusic';
import HistoryIcon from '@mui/icons-material/History';
import LogoutIcon from '@mui/icons-material/Logout';
import AccountCircleIcon from '@mui/icons-material/AccountCircle'; // Icon tài khoản
import { Alert, FormHelperText } from '@mui/material';


// Sử dụng lazy loading cho AddedSongsPage
const AddedSongsPage = lazy(() => import('../AddSongsPage/AddedSongsPage'));

function UserPage() {
  const [songName, setSongName] = useState("");
  const [authorName, setAuthorName] = useState("");
  const [songs, setSongs] = useState([]);
  const [authors, setAuthors] = useState([]);
  const [error, setError] = useState(null);
  const [displayType, setDisplayType] = useState(null);
  const [loading, setLoading] = useState(false);
  const [authorWork, setAuthorWork] = useState(null);
  const { user, setUser } = useUser();
  const navigate = useNavigate();
  const { resetSongs } = useAddedSongs();
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [shouldResetSongName, setShouldResetSongName] = useState(false);
  const [savedSongName, setSavedSongName] = useState("");
  const token = localStorage.getItem('token');
  const [username, setUsername] = useState('');
  const [fullname, setFullname] = useState('');

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const [songError, setSongError] = useState(null);  // Lỗi tên bài hát
  const [authorError, setAuthorError] = useState(null);  // Lỗi tên tác giả


  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };


  // Giải mã token và lấy thông tin người dùng (ví dụ username)
  // let username = null;
  let isActiveUser = null;
  useEffect(() => {
    // Lấy token từ sessionStorage
    const token = sessionStorage.getItem('_umsid');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUsername(decodedToken.username); // Lưu username vào state
        setFullname(decodedToken.fullname)
      } catch (error) {
        console.error('Error decoding the token', error);
      }
    }
  }, []); // Chạy một lần khi component mount

  const contextValue = {
    songs: songs,
    authors: authors,
    error: error
  };

  // const confirmLogout = () => {
  //   localStorage.removeItem('token');

  //   localStorage.removeItem('songs');

  //   localStorage.removeItem('songName');

  //   setUser(null);

  //   navigate("/");

  //   resetSongs();

  //   setShowLogoutModal(false);
  // }


  const confirmLogout = () => {
    // Xóa token khỏi sessionStorage
    sessionStorage.removeItem('_umsid');
    localStorage.removeItem('songs');

    localStorage.removeItem('songName');
    resetSongs();
    // Chuyển hướng người dùng về trang chính
    navigate("/");

    // Đóng modal đăng xuất
    setShowLogoutModal(false);
  }


  const handleLogout = () => {
    setShowLogoutModal(true);
  };


  const handleSongNameSubmit = async (event) => {
    event.preventDefault();

    if (!songName.trim()) {
      setSongError("Vui lòng nhập tên bài hát");  // Lỗi riêng cho bài hát
      return;
    }
    setLoading(true);


    let requestData = {
      ten_bai_hat: songName,
      username: username,
    };

    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      return;
    }
    try {
      const response = await fetch("https://artfinder-backend.vercel.app/user/searchSongByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,

        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const dataSong = await response.json();
        setSongs(dataSong);
        setError(null);
        setDisplayType("songs");
      } else {
        if (response.status === 404) {
          setSongs([]);
          setError("Không tìm thấy bài hát");
        } else if (response.status === 403) {
          // Thêm xử lý khi trạng thái isActive là false
          setSongs([]);
          setError("Tài khoản không hoạt động");
        } else if (response.status === 401) {
          alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
          navigate('/');
        }
        else {
          setError("Lỗi không xác định");
        }
      }
    } catch (err) {
      console.error(err);
      setSongs([]);
      setError("Lỗi không xác định");
    } finally {
      setAuthorName('');

      setLoading(false);
    }
  };



  useEffect(() => {
    if (songs.length > 0) {
      navigate(`/dashboard/songs`);
    }
  }, [songs]);


  const handleAuthorNameSubmit = async (event) => {
    event.preventDefault();

    if (!authorName.trim()) {
      setAuthorError("Vui lòng nhập tên tác giả");  // Lỗi riêng cho tác giả
      return;
    }

    setLoading(true);

    let requestData = {
      ten_tac_gia: authorName,
      username: username,
    };

    const token = sessionStorage.getItem('_umsid');

    if (!token) {
      alert('Token không tồn tại. Vui lòng đăng nhập lại.');
      return;
    }

    try {
      const response = await fetch("https://artfinder-backend.vercel.app/user/searchAuthorByName", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          'Authorization': `Bearer ${token}`,

        },
        body: JSON.stringify(requestData)
      });

      if (response.ok) {
        const data = await response.json();
        setAuthors(data);
        setError(null);
        setDisplayType("authors");

      } else {
        if (response.status === 404) {
          setAuthors([]);
          setError("Không tìm thấy tác giả");
        } else if (response.status === 403) {
          setAuthors([]);
          setError("Tài khoản không hoạt động");
        } else if (response.status === 401) {
          alert('Phiên làm việc của bạn đã hết hạn. Vui lòng đăng nhập lại để tiếp tục.');
          navigate('/');
        }
        else {
          setError("Lỗi không xác định");
        }
      }
    } catch (error) {
      console.error("Có lỗi xảy ra khi gửi yêu cầu đến server:", error);
      setError("Có lỗi xảy ra khi kết nối đến server.");
    } finally {
      setSongName('');

      setLoading(false);
    }
  };


  useEffect(() => {
    if (authors.length > 0) {
      navigate(`/dashboard/authors`);
    }
  }, [authors]);

  const handleSongNameChange = (event) => {
    setSongName(event.target.value); // Cập nhật tên bài hát
    if (songError) setSongError(null); // Xóa lỗi khi người dùng nhập nội dung
  };

  const handleAuthorNameChange = (event) => {
    setAuthorName(event.target.value); // Cập nhật tên tác giả
    if (authorError) setAuthorError(null); // Xóa lỗi khi người dùng nhập nội dung
  };


  let result = 0;
  if (displayType === "songs") {
    result = songs.length;
  } else if (displayType === "authors") {
    result = authors.length;
  } else if (displayType === "authorWork") {
    result = authorWork.bai_hat.length;
  }

  return (
    <div className="container-user">

      <div className="sidebar">

        <div className="dashboard-menu">
          <img src={logo} alt="Logo" className="login-logo" />
          <hr />



          <Box component="form" onSubmit={handleSongNameSubmit} style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="mt-4">
        <TextField
          label="Nhập tên bài hát"
          variant="outlined"
          value={songName}
          onChange={handleSongNameChange}
          fullWidth
          error={!!songError}  // Lỗi riêng cho bài hát
          helperText={songError}  // Thông báo lỗi riêng cho bài hát
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" color="primary">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>

      {/* Form tìm kiếm tác giả */}
      <Box component="form" onSubmit={handleAuthorNameSubmit} style={{ display: 'flex', alignItems: 'center', width: '100%' }} className="mt-4">
        <TextField
          label="Nhập tên tác giả"
          variant="outlined"
          value={authorName}
          onChange={handleAuthorNameChange}
          fullWidth
          error={!!authorError}  // Lỗi riêng cho tác giả
          helperText={authorError}  // Thông báo lỗi riêng cho tác giả
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton type="submit" color="primary">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Box>


          <Tooltip title="Danh Sách Bài Hát Đã Thêm" className="mt-4">
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              onClick={handleOpenPopup}
              style={{ cursor: 'pointer' }}
            >
              <IconButton color="primary">
                <QueueMusicIcon fontSize="large" />
              </IconButton>
              <Typography variant="button" color="primary">
                Danh Sách Bài Hát
              </Typography>
            </Stack>
          </Tooltip>



          <Tooltip title="Lịch Sử Hoạt Động" className="mt-4">
            <Stack
              direction="row"
              alignItems="center"
              component={RouterLink}
              to="/dashboard/history"
              spacing={1}
              style={{ textDecoration: 'none', color: 'inherit' }}
            >
              <IconButton color="primary">
                <HistoryIcon />
              </IconButton>
              <Typography variant="button" color="primary">
                Lịch Sử Hoạt Động
              </Typography>
            </Stack>
          </Tooltip>



          <Popup isOpen={isPopupOpen} onClose={handleClosePopup}>
            <Suspense fallback={<div>Đang tải...</div>}>
              <AddedSongsPage />
            </Suspense>
          </Popup>
        </div>



        <Tooltip title="Đăng xuất">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            onClick={handleLogout}
            style={{ cursor: 'pointer' }}
          >
            <IconButton color="secondary">
              <LogoutIcon fontSize="large" />
            </IconButton>
            <Typography variant="button" color="secondary">
              Đăng xuất
            </Typography>
          </Stack>
        </Tooltip>






      </div>



      <div className="main-content ">
        <h4 className="account-icon" >
          <AccountCircleIcon />
          {fullname}
        </h4>
        {/* <div>
          {error && <p className="error">{error}</p>}

        </div> */}

        <div>
          <SongsContext.Provider value={contextValue}>
            <Outlet />
          </SongsContext.Provider>
        </div>


      </div>

      {loading &&
        <div class="overlay">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }

      <Modal
        open={showLogoutModal}
        onClose={() => setShowLogoutModal(false)}
        aria-labelledby="logout-modal-title"
        aria-describedby="logout-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'center',
          }}
        >
          <Typography id="logout-modal-title" variant="h6" gutterBottom>
            Bạn có chắc chắn muốn đăng xuất?
          </Typography>

          <Stack spacing={2} direction="row" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              onClick={confirmLogout}
            >
              Xác Nhận
            </Button>
            <Button
              variant="outlined"
              color="error"
              onClick={() => setShowLogoutModal(false)}
            >
              Hủy
            </Button>
          </Stack>
        </Box>
      </Modal>
    </div>
  );
}

export default UserPage;
