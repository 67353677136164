import React, { useState, useEffect, lazy, Suspense } from 'react';
import jwtDecode from 'jwt-decode';
import './profilePage.css'
import { useNavigate } from "react-router-dom";
import { useAddedSongs } from '../../components/AddedSongsContext';
import Popup from '../../components/Popup/Popup';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import icon quay lại
import { Dialog, DialogTitle, DialogContent, Button, IconButton, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Chip } from '@mui/material';

const AddedSongsPage = lazy(() => import('../AddSongsPage/AddedSongsPage'));


function ProfilePage() {
  const [decodedData, setDecodedData] = useState(null);
  const [userSongs, setUserSongs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openHistory, setOpenHistory] = useState(null);
  const navigate = useNavigate();
  const { setSongs } = useAddedSongs();



  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedSongs, setSelectedSongs] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);
  const [selectedHistory, setSelectedHistory] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredUserSongs, setFilteredUserSongs] = useState(decodedData || []);


  const handleOpenPopupHistory = (historyItem) => {
    setSelectedHistory(historyItem);
    setOpenPopup(true);
  };

  const handleClosePopupHistory = () => {
    setOpenPopup(false);
    setSelectedHistory(null);
  };


  useEffect(() => {
    const token = sessionStorage.getItem("_umsid"); // Lấy token từ sessionStorage
    if (token) {
      try {
        let decodedData = jwtDecode(token);
        setDecodedData(decodedData);

        // Lấy danh sách bài hát từ API dựa trên username
        fetch(`https://artfinder-backend.vercel.app/user/history`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ username: decodedData.username })
        })
          .then(res => {
            if (!res.ok) {
              throw new Error('Network response was not ok');
            }
            return res.json();
          })
          .then(data => {
            // Chuyển đổi timestamp từ chuỗi sang đối tượng Date và sắp xếp giảm dần
            const sortedData = data.sort((a, b) => {
              const dateA = new Date(a.timestamp.split(' ').join('T')); // Chuẩn hóa timestamp
              const dateB = new Date(b.timestamp.split(' ').join('T'));
              return dateB - dateA; // Sắp xếp giảm dần
            });

            // Cập nhật state với dữ liệu đã sắp xếp
            setUserSongs(sortedData);
            // console.log("history-data", sortedData)
            setLoading(false);
          })
          .catch(error => {
            console.error("Failed to fetch songs:", error);
            setLoading(false);
          });

      } catch (e) {
        console.error("Failed to decode token:", e);
        setLoading(false);
      }
    } else {
      setLoading(false);
    }
  }, []);




  const handleGoBack = () => {
    navigate(-1);
  }

  // const handleAdjustment = (songs) => {
  //     setSongs(songs);

  //     navigate('/user/added-songs');
  // }

  // Hàm mở popup và truyền dữ liệu
  const handleAdjustment = (songs) => {
    setSongs(songs); // Lưu danh sách bài hát vào state
    setIsPopupOpen(true); // Mở popup
    setOpenPopup(false);

  };

  const handleClosePopup = () => {
    setIsPopupOpen(false); // Đóng popup
    // setSongs([]); 
  };


  useEffect(() => {
    if (!userSongs || !Array.isArray(userSongs)) {
      setFilteredUserSongs([]);
      return;
    }

    if (searchTerm === '') {
      setFilteredUserSongs(userSongs);
    } else {
      const filtered = userSongs.filter((historyItem) =>
        historyItem.programName.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredUserSongs(filtered); // Cập nhật danh sách đã lọc
    }
  }, [searchTerm, userSongs]);




  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    return new Intl.DateTimeFormat('vi-VN', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false // Sử dụng 24 giờ thay vì AM/PM
    }).format(date);
  };

  return (
    <div className="profile-history">
      <div>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          sx={{
            color: '#f9a825', // Màu vàng
            borderColor: '#f9a825',
            textTransform: 'none',
            transition: '0.3s',
            marginBottom: '10px', // Thêm margin-bottom
            '&:hover': {
              backgroundColor: 'rgba(249, 168, 37, 0.1)',
              borderColor: '#c17900', // Viền đậm hơn khi hover
            },
          }}
        >
          Quay lại
        </Button>
      </div>
      <h1 className="text-center p-3">Lịch Sử Hoạt Động</h1>

      {loading ? (
        <div className="overlay">
          <div className="lds-roller">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
          </div>
        </div>
      ) : (
        decodedData && (
          <div className="profile-content">
            <div className="content-container">
              <input
                type="text"
                placeholder="Tìm danh sách theo tên chương trình..."
                className="search-input"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />

              {filteredUserSongs.length > 0 ? (
                filteredUserSongs.map((historyItem, index) => (
                  <div key={index} className="history-section">
                    <h3
                      className="history-header"
                      onClick={() => handleOpenPopupHistory(historyItem)}
                    >
                      <ArrowForwardIcon />
                      Danh sách bài hát của chương trình {historyItem.programName} gửi lúc{' '}
                      {formatDate(historyItem.timestamp)}
                    </h3>
                  </div>
                ))
              ) : (
                <p className="m-2">Không có danh sách cần tìm!</p>
              )}

              {/* Popup Dialog */}
              <Dialog open={openPopup} onClose={handleClosePopupHistory} fullWidth maxWidth="md">
                <DialogTitle
                  sx={{
                    textAlign: 'center', // Căn giữa tiêu đề
                    fontSize: '24px', // Tăng kích thước chữ
                    fontWeight: 'bold', // Làm đậm chữ
                    position: 'relative', // Giữ vị trí tương đối để chứa nút đóng
                    padding: '16px', // Tăng khoảng đệm cho đẹp hơn
                  }}
                >
                  Thông tin chi tiết
                  <IconButton
                    aria-label="close"
                    onClick={handleClosePopupHistory}
                    sx={{
                      position: 'absolute',
                      right: 8,
                      top: 8,
                    }}
                  >
                    <CloseIcon />
                  </IconButton>
                </DialogTitle>

                <DialogContent>
                  {selectedHistory && (
                    <>
                      <p className="history-note">Ghi Chú: {selectedHistory.note}</p>
                      <div className="table-and-button-container">
                        <div
                          className="history-table-container"
                          style={{ overflowX: 'auto', marginTop: '16px' }}
                        >
                          <table
                            className="history-table"
                            style={{ width: '100%', tableLayout: 'auto', borderCollapse: 'collapse' }}
                          >
                            <thead>
                              <tr>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>STT</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Code</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Tên Bài Hát</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Nhạc</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Lời</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Nhà Xuất Bản</th>
                                <th style={{ whiteSpace: 'nowrap', padding: '8px', textAlign: 'left' }}>Trạng Thái</th>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedHistory.addedSongs.map((song, songIndex) => (
                                <tr key={song._id || song.ma_code_bai_hat}>
                                  <td style={{ padding: '8px' }}>{songIndex + 1}</td>
                                  <td style={{ padding: '8px' }}>{song.ma_code_bai_hat}</td>
                                  <td style={{ padding: '8px' }}>{song.ten_bai_hat[0]}</td>
                                  <td style={{ padding: '8px' }}>{song.nhac}</td>
                                  <td style={{ padding: '8px' }}>{song.loi}</td>
                                  <td style={{ padding: '8px' }}>{song.publisher}</td>
                                  <td>
                    <Chip
                      label={song.status}
                      color={song.status === 'Được sử dụng' ? 'success' : 'warning'}
                      sx={{
                        backgroundColor: song.status === 'Được sử dụng' ? 'green' : 'orange',
                        color: 'white',
                        fontWeight: 'bold',
                        width: '120px',  // Cố định chiều rộng
                        height: '35px',  // Chiều cao nhất quán
                        borderRadius: '8px',  // Bo góc cho đẹp
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  </td> 
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>

                        <Button
                          variant="contained"
                          onClick={() => handleAdjustment(selectedHistory.addedSongs)}
                          sx={{
                            backgroundColor: '#1976d2',
                            color: '#fff',
                            textTransform: 'none',
                            padding: '8px 16px',
                            borderRadius: '8px',
                            transition: '0.3s',
                            marginTop: '10px',
                            '&:hover': {
                              backgroundColor: '#115293',
                            },
                          }}
                        >
                          Điều chỉnh
                        </Button>
                      </div>
                    </>
                  )}
                </DialogContent>
              </Dialog>
            </div>
          </div>
        )
      )}

      {/* Popup hiển thị AddedSongsPage */}
      <Popup isOpen={isPopupOpen} onClose={handleClosePopup}>
        <Suspense fallback={<div>Đang tải...</div>}>
          <AddedSongsPage songs={selectedSongs} />
        </Suspense>
      </Popup>
    </div>
  );


}

export default ProfilePage;




