import React, { useState, useEffect } from 'react';
import { useAddedSongs } from '../../components/AddedSongsContext';
import './addedSongsPage.css'
import axios from 'axios';
import { useUser } from '../../components/UserContext';
import jwtDecode from 'jwt-decode';
import { useNavigate } from "react-router-dom";
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete'; // Import icon Delete
import { TextField, Button, Box, Typography, Modal, Stack } from '@mui/material';
import { Chip } from '@mui/material';


function AddedSongsPage() {
  const { addedSongs, removeSong, resetSongs } = useAddedSongs();
  const [email, setEmail] = useState('');
  const [note, setNote] = useState('');
  const [programName, setProgramName] = useState('');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const { user } = useUser();
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false); // Quản lý trạng thái nút

  const navigate = useNavigate();


  const handleRemoveSong = (songIndex) => {
    removeSong(songIndex);
  };

  useEffect(() => {
    const token = sessionStorage.getItem("_umsid"); // Lấy token từ sessionStorage
    if (token) {
      try {
        const decodedData = jwtDecode(token);
        if (decodedData && decodedData.email) {
          setEmail(decodedData.email);  // Thiết lập giá trị mặc định cho trường email từ thông tin người dùng
        }
      } catch (e) {
        console.error("Failed to decode token:", e);
      }
    }
  }, []);




  const handleSubmit = (event) => {
    event.preventDefault();
    setShowConfirmModal(true);
  };

  const finalSubmit = async (event) => {
    event.preventDefault();
  
    const token = sessionStorage.getItem("_umsid");
    let decodedData = {};
  
    if (token) {
      try {
        decodedData = jwtDecode(token);
      } catch (e) {
        console.error("Failed to decode token:", e);
        return;
      }
    }
  
    const payload = {
      addedSongs,
      email: decodedData.email || email,
      note,
      programName,
      username: decodedData.username || user?.username,
    };
  
    try {
      const response = await axios.post(
        'https://artfinder-backend.vercel.app/user/api/send-email',
        payload
      );
  
      if (response.status === 200) {
        const historyData = {
          addedSongs,
          email: decodedData.email || email,
          note,
          programName,
          username: decodedData.username || user?.username,
          timestamp: moment().toISOString(),
        };
  
        const saveToHistoryResponse = await axios.post(
          'https://artfinder-backend.vercel.app/user/save-to-history',
          historyData
        );
  
        if (saveToHistoryResponse.status === 200) {
          setShowSuccessModal(true);
          setShowConfirmModal(false);
          setNote('');
          resetSongs();
        } else {
          console.error('Failed to save data to history:', saveToHistoryResponse.data.error);
          setShowErrorModal(true);
        }
      } else {
        setShowErrorModal(true);
        setShowConfirmModal(false);
      }
    } catch (error) {
      console.error('Failed to send email:', error);
      setShowErrorModal(true);
    }
  };


  const handleFinalSubmit = async (event) => {
    setIsSubmitting(true); // Vô hiệu hóa nút và hiển thị "Đang gửi..."
    await finalSubmit(event); // Gọi hàm gửi dữ liệu
    setIsSubmitting(false); // Mở lại nút sau khi hoàn thành
  };


  const handleCloseModals = () => {
    setShowConfirmModal(false);
    setShowErrorModal(false);
    setShowSuccessModal(false)
  };


  

  return (
    <div className="addedsongs-container">
      <h1 className="text-center p-2">Danh Sách Bài Hát</h1>

      <div className="addedsongs-content-wrapper">
        <div className="addedsongs-table-container">
        
          <table className="addedsongs-table">
            <thead>
              <tr>
                <th className="column-stt">STT</th>
                <th className="column-ma">Code</th>
                <th className="column-ten-addedsongs">Tên Bài Hát</th>
                <th>Nhạc</th>
                <th>Lời</th>
                <th>Nhà Xuất Bản</th>
                <th>Trạng Thái</th>
                <th className="action-remove"></th>
              </tr>
            </thead>
            <tbody>
              {addedSongs && addedSongs.length > 0 ? (
                addedSongs.map((song, index) => (
                  <tr key={index}>
                    <td>{index + 1}.</td>
                    <td className="ma-code">{song.ma_code_bai_hat}</td>
                    <td>
                      {Array.isArray(song.ten_bai_hat)
                        ? song.ten_bai_hat.join(', ')
                        : song.ten_bai_hat}
                    </td>
                    <td>{song.nhac}</td>
                    <td>{song.loi}</td>
                    <td>{song.publisher}</td>
                    <td>
  <Chip
    label={song.status}
    color={song.status === 'Được sử dụng' ? 'success' : 'warning'}
    sx={{
      backgroundColor: song.status === 'Được sử dụng' ? 'green' : 'orange',
      color: 'white',
      fontWeight: 'bold',
      width: '120px',  // Cố định chiều rộng
      height: '35px',  // Chiều cao nhất quán
      borderRadius: '8px',  // Bo góc cho đẹp
      textAlign: 'center',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      margin: 'auto',
    }}
  />
</td> 
                    <td>
                      <Button
                        variant="contained"
                        color="error" // Màu đỏ cho nút Xóa
                        startIcon={<DeleteIcon />} // Icon xóa ở đầu
                        onClick={() => handleRemoveSong(index)}
                        sx={{
                          textTransform: 'none', // Không viết hoa chữ
                          padding: '4px 8px', // Khoảng đệm trong nút
                          borderRadius: '8px', // Bo góc
                          transition: '0.3s', // Hiệu ứng mượt khi hover
                          '&:hover': {
                            backgroundColor: '#b71c1c', // Màu nền đậm hơn khi hover
                          },
                        }}
                      >
                        Xóa
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" className="no-songs-message">
                    Không có bài hát nào được thêm.
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        <Box
          className="form-container-addedsongspage"
          sx={{
            border: '1px solid #ddd',
            padding: '24px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            maxWidth: '400px',
            margin: '0 auto',
            textAlign: 'center', // Căn giữa nội dung trong form
          }}
        >
          <Typography
            variant="h5"
            component="h4"
            gutterBottom
            sx={{ marginBottom: '10px' }} // Khoảng cách dưới tiêu đề
          >
            Vui lòng điền đầy đủ thông tin!
          </Typography>

          <form onSubmit={handleSubmit} className="custom-form">
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Tên Chương Trình"
              type="text"
              value={programName}
              onChange={(e) => setProgramName(e.target.value.toUpperCase())}
              fullWidth
              margin="normal"
              required
            />
            <TextField
              label="Ghi Chú"
              multiline
              rows={4}
              value={note}
              onChange={(e) => setNote(e.target.value)}
              fullWidth
              margin="normal"
              required
            />
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              sx={{
                marginTop: '16px',
                textTransform: 'none',
              }}
            >
              Gửi thông tin
            </Button>
          </form>
        </Box>




      </div>



      {loading &&
        <div class="overlay">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }



<Modal
      open={showConfirmModal}
      onClose={() => setShowConfirmModal(false)}
      aria-labelledby="confirm-modal-title"
      aria-describedby="confirm-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          border: '2px solid #000',
          borderRadius: '8px',
          boxShadow: 24,
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Typography id="confirm-modal-title" variant="h6" gutterBottom>
          Bạn có muốn gửi danh sách không?
        </Typography>

        <Stack spacing={2} direction="row" justifyContent="center" mt={3}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleFinalSubmit}
            disabled={isSubmitting} // Vô hiệu hóa nút nếu đang xử lý
          >
            {isSubmitting ? 'Đang gửi...' : 'Xác Nhận Gửi'} {/* Văn bản động */}
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={() => setShowConfirmModal(false)}
            disabled={isSubmitting} // Vô hiệu hóa nút nếu đang xử lý
          >
            Hủy
          </Button>
        </Stack>
      </Box>
    </Modal>





      {/* Modal Thành Công */}
      <Modal
        open={showSuccessModal}
        onClose={handleCloseModals}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#e8f5e9', // Nền xanh nhạt (thành công)
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="success-modal-title" variant="h6" gutterBottom>
            Thành công!
          </Typography>
          <Typography id="success-modal-description" variant="body1" mb={3}>
            Chúng tôi sẽ kiểm tra và phản hồi sớm.
          </Typography>
          <Button
            variant="contained"
            color="success"
            onClick={handleCloseModals}
          >
            Đóng
          </Button>
        </Box>
      </Modal>

      {/* Modal Lỗi */}
      <Modal
        open={showErrorModal}
        onClose={handleCloseModals}
        aria-labelledby="error-modal-title"
        aria-describedby="error-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: '#ffebee', // Nền đỏ nhạt (lỗi)
            borderRadius: '8px',
            boxShadow: 24,
            p: 4,
            textAlign: 'center',
          }}
        >
          <Typography id="error-modal-title" variant="h6" gutterBottom>
            Lỗi!
          </Typography>
          <Typography id="error-modal-description" variant="body1" mb={3}>
            Đã xảy ra sự cố khi gửi email. Vui lòng thử lại sau!
          </Typography>
          <Button
            variant="contained"
            color="error"
            onClick={handleCloseModals}
          >
            Đóng
          </Button>
        </Box>
      </Modal>


    </div>
  );
}

export default AddedSongsPage;
