import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './User/userPage.css'
import { useAddedSongs } from "../components/AddedSongsContext";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button'; // Import Button từ MUI
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import icon quay lại
import { Chip } from '@mui/material';


function SongDetail() {
  const { songCode } = useParams();
  const [songWork, setsongWork] = useState(null);
  const { addedSongs, addSong } = useAddedSongs();
  const navigate = useNavigate();

  useEffect(() => {
    // Gửi yêu cầu lên server để lấy thông tin chi tiết của tác giả dựa trên mã tác giả
    const fetchSongDetail = async () => {
      try {
        const response = await fetch(`https://artfinder-backend.vercel.app/user/api/song/${songCode}`);

        if (response.ok) {
          const data = await response.json();
          setsongWork([data]);
        } else {
          console.error("Lỗi khi lấy thông tin chi tiết bai hat");
        }
      } catch (err) {
        console.error("Có lỗi xảy ra:", err);
      }
    };

    fetchSongDetail();
  }, [songCode]);

  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };

  const handleAddClick = (song) => {
    addSong(song);
  }

  const handleGoBack = () => {
    navigate(-1);
  }


  return (
    <div className="mt-5">
      <div>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          sx={{
            color: '#f9a825', // Màu vàng
            borderColor: '#f9a825',
            textTransform: 'none',
            transition: '0.3s',
            marginBottom: '10px', // Thêm margin-bottom
            '&:hover': {
              backgroundColor: 'rgba(249, 168, 37, 0.1)',
              borderColor: '#c17900', // Viền đậm hơn khi hover
            },
          }}
        >
          Quay lại
        </Button>
      </div>
      {songWork && (
        <table >
          <thead>
            <tr>
              <th className="column-stt">STT</th>
              <th className="column-ma">Code</th>
              <th className="column-ten">Tên Bài Hát</th>
              <th>Nhạc</th>
              <th>Lời</th>
              <th>Nhà Xuất Bản</th>
              <th>Trạng Thái</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {songWork.map((song, index) => (
              <tr key={index}>
                <td>{index + 1}.</td>
                <td className="ma-code">{song.ma_code_bai_hat}</td>
                <td>{Array.isArray(song.ten_bai_hat) ? song.ten_bai_hat.join(', ') : song.ten_bai_hat}</td>
                <td>{song.nhac}</td>
                <td>{song.loi}</td>
                <td>{song.publisher}</td>
                <td>
                  <Chip
                    label={song.status}
                    color={song.status === 'Được sử dụng' ? 'success' : 'warning'}
                    sx={{
                      backgroundColor: song.status === 'Được sử dụng' ? 'green' : 'orange',
                      color: 'white',
                      fontWeight: 'bold',
                      width: '120px',  // Cố định chiều rộng
                      height: '35px',  // Chiều cao nhất quán
                      borderRadius: '8px',  // Bo góc cho đẹp
                      textAlign: 'center',
                      justifyContent: 'center',
                      alignItems: 'center',
                      display: 'flex',
                      margin: 'auto',
                    }}
                  />
                </td>
                <td>
                    {isSongAdded(song) ? (
                      <Button
                        variant="contained"
                        color="success"
                        disabled
                        sx={{
                          textTransform: 'none',
                          cursor: 'default',
                          width: '100px',       // Cố định chiều rộng
                          minWidth: '100px',    // Đảm bảo không nhỏ hơn
                          maxWidth: '100px',    // Đảm bảo không lớn hơn
                          height: '40px',       // Chiều cao cố định
                          '&:hover': {
                            backgroundColor: 'green',
                          },
                        }}
                      >
                        Đã thêm
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleAddClick(song)}
                        sx={{
                          textTransform: 'none',
                          width: '100px',       // Cố định chiều rộng
                          minWidth: '100px',    // Đảm bảo không nhỏ hơn
                          maxWidth: '100px',    // Đảm bảo không lớn hơn
                          height: '40px',       // Chiều cao cố định
                          transition: '0.3s',
                          '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.1)',
                            borderColor: 'primary.main',
                          },
                        }}
                      >
                        Thêm
                      </Button>
                    )}
                  </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}


    </div>
  )
}

export default SongDetail
