import { useUser } from './UserContext';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children }) {
  const { user } = useUser();
  const token = sessionStorage.getItem('_umsid'); // Lấy token từ sessionStorage

  
  // Nếu có user hoặc token, cho phép truy cập
  return user || token ? children : <Navigate to="/" />; // Nếu không có, điều hướng về trang login
}

export default PrivateRoute;
