import './App.css';
import UserPage from './pages/User/UserPage';
import LoginPage from './pages/LogIn/LoginPage';
import AuthorDetailPage from './pages/AuthorDetailPage';
import SongDetailPage from './pages/SongDetailPage'
// import PublicRoutes from './routes/Router';
import { BrowserRouter as Router, Route, Routes} from "react-router-dom";
import AuthorsPage from './pages/AuthorsPage';
import SongsPage from './pages/SongsPage';
import { AddedSongsProvider } from './components/AddedSongsContext';
import AddedSongsPage from './pages/AddSongsPage/AddedSongsPage';
import AdminPage from './pages/Admin/AdminPage';
import { UserProvider } from './components/UserContext';
import PrivateRoute from './components/PrivateRoute';
import ProfilePage from './pages/ProfilePage/ProfilePage';
import ManageUsers from './pages/ManageUserPage/ManageUsers';
import ManageSongs from './pages/ManageSongsPage/ManageSongs';
import ManageAuthors from './pages/ManageAuthorsPage/ManageAuthors';
import ManagContracts from './pages/ManageContract/ManageContracts';
import ManageContracts_North from './pages/ManageContract/ManageContracts_North';
import ManagYoutubeCheckList from './pages/ManageYoutueCheckListPage/ManageYoutubeCheckList';

function App() {
  return (
    <UserProvider>
      <AddedSongsProvider>
        <Router>
          <Routes>
            <Route path="/" element={<LoginPage />} />
            <Route path="/dashboard" element={
              <PrivateRoute>
                <UserPage />
              </PrivateRoute>
            }>
              <Route path="authors" element={<AuthorsPage />} />
              <Route path="authors/:authorCode" element={<AuthorDetailPage />} />
              <Route path="songs" element={<SongsPage />} />
              <Route path="songs/:songCode" element={<SongDetailPage />} />
              <Route path="history" element={<ProfilePage />} />

            </Route>

        
            {/* <Route path="/history" element={
              <PrivateRoute>
                <ProfilePage />
              </PrivateRoute>
            } /> */}
          </Routes>
        </Router>
      </AddedSongsProvider>
    </UserProvider>
  );
}







export default App;




            {/* <Route path="/admin" element={
              <PrivateRoute>
                <AdminPage />
              </PrivateRoute>
            }>
              <Route path="users" element={<ManageUsers />} />
              <Route path="songs" element={<ManageSongs />} />
              <Route path="authors" element={<ManageAuthors />} />
              <Route path='contracts' element={<ManagContracts/>}/>
              <Route path='contracts_north' element={<ManageContracts_North/>}/>
              <Route path='youtube_checklist' element={<ManagYoutubeCheckList/>}/>

            </Route> */}