import React, { useContext, useState, useEffect } from "react";
import SongsContext from "../components/SongsContext";
import { useNavigate } from "react-router-dom";
import Button from '@mui/material/Button'; // Import Button từ MUI


function AuthorsPage() {
    const [error, setError] = useState(null);
    const context = useContext(SongsContext);
    const { authors } = context;
    const navigate = useNavigate();

    const handleAuthorCodeClick = async (authorCode) => {
        navigate(`/dashboard/authors/${authorCode}`);
    };
    useEffect(() => {
        let result = authors ? authors.length : 0;
        if (result === 0) {
            setError("Không tìm thấy tác giả!");
        } else {
            setError(null);
        }
    }, [authors])

    return (
        <div className="mt-5">
    <p className="result">Số kết quả tìm kiếm: {authors.length}</p>
    {authors && (
        <table>
            <thead>
                <tr>
                    <th className="column-stt">STT</th>
                    <th className="column-ma">Code</th>
                    <th className="column-tentg">Tên Tác Giả</th>
                    <th className="column-tenthat">Bút Danh</th>
                </tr>
            </thead>
            <tbody>
                {error && (
                    <tr>
                        <td colSpan="4" className="error">
                            {error}
                        </td>
                    </tr>
                )}
                {authors.map((author, index) => (
                    <tr key={index}>
                        <td>{index + 1}.</td>
                        <td className="ma-code">{author.ma_tac_gia}</td>
                        <td className="ten-that-tac-gia">{author.ten_tac_gia}</td>
                        <td className="but_danh">
                            <div className="author-detail">
                                {Array.isArray(author.but_danh) ? (
                                    <div className="tac-pham-container">
                                        {author.but_danh.map((but_danh, index) => (
                                            <p className="tac-pham" key={index}>
                                                {but_danh}
                                            </p>
                                        ))}
                                    </div>
                                ) : (
                                    <p className="tac-pham">{author.but_danh}</p>
                                )}
                                <Button
                                    className="view-works-btn"
                                    variant="outlined"
                                    onClick={() => handleAuthorCodeClick(author.ma_tac_gia)}
                                    sx={{
                                        color: '#38ef7d',
                                        borderColor: '#38ef7d',
                                        textTransform: 'none',
                                        transition: '0.3s',
                                        '&:hover': {
                                            backgroundColor: 'rgba(56, 239, 125, 0.1)',
                                            borderColor: '#32d96d',
                                        },
                                    }}
                                >
                                    Tác phẩm
                                </Button>
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    )}
</div>

    );
}

export default AuthorsPage;
