import React, { useContext, useState, useEffect } from "react";
import SongsContext from "../components/SongsContext";
import './User/userPage.css'
import { useAddedSongs } from "../components/AddedSongsContext";
import Button from '@mui/material/Button'; // Import Button từ MUI
import { Chip } from '@mui/material';



function SongsPage() {
  const [error, setError] = useState(null);
  const context = useContext(SongsContext);
  const { songs } = context;
  const { addedSongs, addSong } = useAddedSongs();

  useEffect(() => {
    let result = songs ? songs.length : 0;
    // console.log("TEST SONGS", songs)
    if (result === 0) {
      setError("Không tìm thấy bài hát!");
    } else {
      setError(null);
    }
  }, [songs]);


  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };
  const handleAddClick = (song) => {
    addSong(song);
  }

  return (
    <div className="mt-5">
      <p className="result">Số kết quả tìm kiếm: {songs.length}</p>
      {songs && (
        <div class="table-container">
          <table>
            <thead>
              <tr>
                <th className="column-stt">STT</th>
                <th className="column-ma">Code</th>
                <th >Tên Bài Hát</th>
                <th>Nhạc</th>
                <th>Lời</th>
                <th>Nhà Xuất Bản</th>
                <th>Trạng Thái</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {error && (
                <tr>
                  <td colSpan="9" className="error">
                    {error}
                  </td>
                </tr>
              )}
              {songs.map((song, index) => (
                <tr key={index}>
                  <td>{index + 1}.</td>
                  <td className="ma-code">{song.ma_code_bai_hat}</td>
                  <td>
                    {Array.isArray(song.ten_bai_hat)
                      ? song.ten_bai_hat.join(', ')
                      : song.ten_bai_hat}
                  </td>
                  <td>{song.nhac}</td>
                  <td>{song.loi}</td>
                  <td>{song.publisher}</td>
                  <td>
                    <Chip
                      label={song.status}
                      color={song.status === 'Được sử dụng' ? 'success' : 'warning'}
                      sx={{
                        backgroundColor: song.status === 'Được sử dụng' ? 'green' : 'orange',
                        color: 'white',
                        fontWeight: 'bold',
                        width: '120px',  // Cố định chiều rộng
                        height: '35px',  // Chiều cao nhất quán
                        borderRadius: '8px',  // Bo góc cho đẹp
                        textAlign: 'center',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        margin: 'auto',
                      }}
                    />
                  </td>
                  <td>
                    {isSongAdded(song) ? (
                      <Button
                        variant="contained"
                        color="success"
                        disabled
                        sx={{
                          textTransform: 'none',
                          cursor: 'default',
                          width: '100px',       // Cố định chiều rộng
                          minWidth: '100px',    // Đảm bảo không nhỏ hơn
                          maxWidth: '100px',    // Đảm bảo không lớn hơn
                          height: '40px',       // Chiều cao cố định
                          '&:hover': {
                            backgroundColor: 'green',
                          },
                        }}
                      >
                        Đã thêm
                      </Button>
                    ) : (
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => handleAddClick(song)}
                        sx={{
                          textTransform: 'none',
                          width: '100px',       // Cố định chiều rộng
                          minWidth: '100px',    // Đảm bảo không nhỏ hơn
                          maxWidth: '100px',    // Đảm bảo không lớn hơn
                          height: '40px',       // Chiều cao cố định
                          transition: '0.3s',
                          '&:hover': {
                            backgroundColor: 'rgba(25, 118, 210, 0.1)',
                            borderColor: 'primary.main',
                          },
                        }}
                      >
                        Thêm
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>

  );
}

export default SongsPage;

