import React, { useState } from "react";
import Form from "react-bootstrap/Form";
import "./loginPage.css";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../components/UserContext";
import jwtDecode from 'jwt-decode';
import { Box, Button, Container, TextField, Typography, CircularProgress, IconButton, InputAdornment  } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';

function LoginPage() {
  const navigate = useNavigate();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [usernameError, setUsernameError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const { setUser } = useUser();
  const [showPassword, setShowPassword] = useState(false);

  const [isLoading, setIsLoading] = useState(false); 




  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!username.trim()) {
      setUsernameError('Vui lòng nhập tên đăng nhập.');
      return;
    }
    if (!password.trim()) {
      setPasswordError('Vui lòng nhập mật khẩu.');
      return;
    }

    setIsLoading(true); 

    try {
      const response = await fetch(`https://artfinder-backend.vercel.app/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ username, password }),
      });

      const data = await response.json();

      if (response.ok) {
        sessionStorage.setItem('_umsid', data.accessToken);
        navigate('/dashboard');
      } else {
        alert(`Lỗi đăng nhập: ${data.message}`);
      }
    } catch (error) {
      alert(`Đã xảy ra lỗi: ${error.message}`);
    } finally {
      setIsLoading(false); 
    }
  };
  



  return (
    <div className="container-login">
        <Container
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
      }}
    >
      <Box component="form" onSubmit={handleSubmit} className="form-container">
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          sx={{ color: '#ffffff' }}
        >
          Đăng Nhập
        </Typography>

        <TextField
          label="Tên đăng nhập"
          variant="outlined"
          fullWidth
          margin="normal"
          value={username}
          onChange={(e) => {
            setUsername(e.target.value);
            setUsernameError('');
          }}
          error={!!usernameError}
          helperText={usernameError}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            input: { color: '#ffffff' },
            label: { color: '#bbdefb' },
            '& .Mui-focused': { color: '#ffffff' },
          }}
        />

        <TextField
          label="Mật khẩu"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          fullWidth
          margin="normal"
          value={password}
          onChange={(e) => {
            setPassword(e.target.value);
            setPasswordError('');
          }}
          error={!!passwordError}
          helperText={passwordError}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            input: { color: '#ffffff' },
            label: { color: '#bbdefb' },
            '& .Mui-focused': { color: '#ffffff' },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword((prev) => !prev)}
                  edge="end"
                  sx={{ color: '#ffffff' }}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{
            mt: 2,
            py: 1.5,
            backgroundColor: '#64b5f6',
            color: '#ffffff',
            '&:hover': {
              backgroundColor: '#42a5f5',
            },
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          disabled={isLoading} // Vô hiệu hóa nút trong khi đang loading
        >
          {isLoading ? (
            <CircularProgress size={24} sx={{ color: '#ffffff' }} />
          ) : (
            'ĐĂNG NHẬP'
          )}
        </Button>
      </Box>
    </Container>

    
    </div>
  );
}

export default LoginPage;
