// Popup.js
import React from 'react';
import './popup.css'
import Button from '@mui/material/Button'; // Import Button từ MUI
import CloseIcon from '@mui/icons-material/Close'; // Import icon Close

const Popup = ({ children, isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <button className="popup-close" onClick={onClose}>
        <span>&times;</span> 
        </button>
        {children}
      </div>
    </div>
  );
};

export default Popup;
