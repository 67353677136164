import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import "./User/userPage.css";
import { useAddedSongs } from "../components/AddedSongsContext";
import Button from '@mui/material/Button'; // Import Button từ MUI
import ArrowBackIcon from '@mui/icons-material/ArrowBack'; // Import icon quay lại


function AuthorDetailPage() {
  const { authorCode } = useParams();
  const [authorWork, setauthorWork] = useState(null);
  const navigate = useNavigate();
  const [isLoaded, setIsLoaded] = useState(false); // Thêm biến trạng thái 
  const [loading, setLoading] = useState(false);
  const [songCount, setSongCount] = useState(0);
  const { addedSongs, addSong } = useAddedSongs();

  useEffect(() => {
    const fetchAuthorDetail = async () => {
      setLoading(true);

      try {
        const response = await fetch(`https://artfinder-backend.vercel.app/user/api/songs/${authorCode}`);

        if (response.ok) {
          const data = await response.json();
          setauthorWork(data);
          setIsLoaded(true); // Cập nhật trạng thái sau khi dữ liệu được tải
          setLoading(false);

          // Cập nhật số lượng bài hát từ dữ liệu authorWork
          const count = data.bai_hat.length;
          setSongCount(count);
        } else {
          console.error("Lỗi khi lấy thông tin chi tiết tac gia");
        }
      } catch (err) {
        console.error("Có lỗi xảy ra:", err);
      }
    };

    if (!isLoaded) { // Chỉ gọi API nếu dữ liệu chưa được tải
      fetchAuthorDetail();
    }
  }, [authorCode, isLoaded]); // Thêm isLoaded vào danh sách phụ thuộc

  const showSongDetails = (songCode) => {
    // console.log("MABAIHAT", songCode)
    navigate(`/dashboard/songs/${songCode}`);
  }

  const isSongAdded = song => {
    return addedSongs.some(addedSong => addedSong.ma_code_bai_hat === song.ma_code_bai_hat);
  };

  const handleAddClick = (song) => {
    addSong(song);
  }

  const handleGoBack = () => {
    navigate(-1);
  }

  return (
    <div className="mt-5">
      <div>
        <Button
          variant="outlined"
          startIcon={<ArrowBackIcon />}
          onClick={handleGoBack}
          sx={{
            color: '#f9a825', // Màu vàng
            borderColor: '#f9a825',
            textTransform: 'none',
            transition: '0.3s',
            marginBottom: '10px', // Thêm margin-bottom
            '&:hover': {
              backgroundColor: 'rgba(249, 168, 37, 0.1)',
              borderColor: '#c17900', // Viền đậm hơn khi hover
            },
          }}
        >
          Quay lại
        </Button>    </div>
      <p className="result">Số kết quả tìm kiếm:  {songCount}</p>
      {loading &&
        <div class="overlay">
          <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>
      }
      {authorWork && (
        <div className="author-table-container">
          <table className="author-table">
            <thead>
              <tr>
                <th className="column-stt">STT</th>
                <th className="column-ma">Code</th>
                <th className="column-tentg">Tên Tác Giả</th>
                <th className="column-tenthat">Bút Danh</th>
                <th className="column-tacpham">Tác phẩm</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1.</td>
                <td>{authorWork.ma_tac_gia}</td>
                <td>{authorWork.ten_tac_gia}</td>
                <td>
                  {Array.isArray(authorWork.but_danh) ? (
                    authorWork.but_danh.map((but_danh, index) => (
                      <p key={index}>{but_danh}</p>
                    ))
                  ) : (
                    <p>{authorWork.but_danh}</p>
                  )}
                </td>
                <td>
                  <div className="tacpham-container">
                    {authorWork.bai_hat.map((song, index) => (
                      <div className="song-group" key={index}>
                        {song.ten_bai_hat.map((title, subIndex) => (
                          <p className="song-title" key={subIndex}>{title}</p>
                        ))}
                        <div className="buttons-wrapper">
                          <Button
                            variant={isSongAdded(song) ? 'contained' : 'outlined'}
                            color={isSongAdded(song) ? 'success' : 'primary'}
                            disabled={isSongAdded(song)}
                            onClick={!isSongAdded(song) ? () => handleAddClick(song) : undefined}
                            sx={{
                              textTransform: 'none',
                              transition: '0.3s',
                              '&:hover': {
                                backgroundColor: isSongAdded(song)
                                  ? 'green'
                                  : 'rgba(25, 118, 210, 0.1)',
                              },
                            }}
                          >
                            {isSongAdded(song) ? 'Đã Thêm' : 'Thêm'}
                          </Button>

                          <Button
                            className="btn-detail"
                            variant="outlined"
                            onClick={() => showSongDetails(song.ma_code_bai_hat)}
                            sx={{
                              color: '#38ef7d',
                              borderColor: '#38ef7d',
                              textTransform: 'none',
                              transition: '0.3s',
                              '&:hover': {
                                backgroundColor: 'rgba(56, 239, 125, 0.1)',
                                borderColor: '#32d96d',
                              },
                            }}
                          >
                            Chi tiết
                          </Button>
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>




      )}

    </div>
  );



}

export default AuthorDetailPage;
